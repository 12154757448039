<template>
  <div class="route-container">
    <div class="container">
      <div class="intro text-center">
        <img
          src="../../../images/doodle_orange.svg"
          class="doodle-bg doodle-bg-orange"
        />
        <img
          src="../../../images/doodle_blue.svg"
          class="doodle-bg doodle-bg-blue"
        />

        <div class="intro-pad">
          <h1 class="margin-bottom-base">
            <b>Simplify your design selection workflow</b>
          </h1>

          <p class="font-big">
            Glasshouse streamlines the design selection process for home
            builders, designers and communities by centralizing your pricing,
            products and documents all online.
          </p>

          <a-button
            size="large"
            type="primary"
            @click="signup"
            class="ant-btn-xl ant-btn-orange margin-top-sm"
            ><b>Get started today</b>
            <icon name="arrow_right" class="margin-left-sm" size="24"
          /></a-button>

          <a-button
            size="large"
            class="ant-btn-xl margin-top-sm margin-left-sm"
            @click.prevent="$bus.$emit('schedule-demo')"
            >Schedule a demo</a-button
          >

          <p class="margin-top-base font-small">
            <icon name="doodle_check" size="20" raw /> <b> No commitment</b>, 30
            day free trial.
          </p>
        </div>
      </div>
    </div>

    <div class="cyan-gradient main-screenshot">
      <img
        src="../../../images/screenshot.png"
        width="927"
        srcset="
          ../../../images/screenshot.png    1x,
          ../../../images/screenshot@2x.png 2x
        "
        class="screenshot"
      />
    </div>

    <section class="section-ups">
      <div class="container">
        <h2 class="text-center">Everything you need in one platform.</h2>
        <p class="font-big text-center">
          Glasshouse is a centralized solution for designers & renovation
          companies.
        </p>

        <br />

        <a-row type="flex" justify="space-between" class="margin-top-lg">
          <a-col :span="6">
            <img src="../../../images/catalog_management.svg" />

            <h5 class="margin-top-lg">Catalog management</h5>

            <p>
              Host your entire catalog of options on the cloud and make it
              easily discoverable, searchable, and a breeze to update.
            </p>
          </a-col>

          <a-col :span="6">
            <img src="../../../images/team_collaboration.svg" />

            <h5 class="margin-top-lg">Team collaboration</h5>

            <p>
              Get your entire team on the same page. Everyone has the same up to
              date information on meetings, pricing, floorplans, etc..
            </p>
          </a-col>

          <a-col :span="6">
            <img src="../../../images/pricing_automation.svg" />

            <h5 class="margin-top-lg">Pricing automation</h5>

            <p>
              Say goodbye to excel. Glasshouse automatically calculates upgrade
              prices based on your multipliers and standard options.
            </p>
          </a-col>
        </a-row>
      </div>
    </section>

    <section
      class="xcyan-gradient cyan-bg section-with-overflow-screenshot section-dual"
    >
      <div class="container">
        <a-row type="flex" justify="space-between" :gutter="100" align="middle">
          <a-col :span="12">
            <div style="color: #ff5823" class="margin-bottom-lg">
              <icon name="shapes" size="24" class="margin-right-xs" />
              <b>Pricing automation</b>
            </div>

            <h2>Less spreadsheets, more automation.</h2>

            <p>
              Managing prices, documents, and notes across hundreds of records
              can be exhausting. Glasshouse automatically manages the cost of
              upgrades so that designers can stay engaged with home-buyers
              during the selection process. Whenever a price from a vendor
              changes, simply update the catalog without worrying if designers
              are using the most recent price sheet.
            </p>

            <p>
              GlassHouse brings every product, price, and detail into focus
              without all the manual work of managing an excel workbook.
            </p>

            <div class="margin-top-base">
              <a href="/auth/signup"
                ><b>Try it out</b> <icon name="arrow_right" size="24"
              /></a>
            </div>
          </a-col>

          <a-col :span="12">
            <img
              src="../../../images/screenshot_selection.png"
              width="583"
              srcset="
                ../../../images/screenshot_selection.png    1x,
                ../../../images/screenshot_selection@2x.png 2x
              "
              class="screenshot"
            />
          </a-col>
        </a-row>
      </div>
    </section>

    <section class="section-dual">
      <div class="container">
        <a-row type="flex" justify="space-between" :gutter="100" align="middle">
          <a-col :span="12">
            <img src="../../../images/collaboration.svg" width="494" />
          </a-col>

          <a-col :span="12">
            <div style="color: #2596ff" class="margin-bottom-lg">
              <icon name="collaboration" size="24" class="margin-right-xs" />
              <b>Collaboration</b>
            </div>

            <h2>Team collaboration, in realtime.</h2>

            <p>
              Designers, contractors, communities can finally collaborate in
              realtime. Assign meetings to designers, update catalog pricing on
              the fly, or add a special note to a line item.
            </p>

            <p>
              Everyone is on the same page and gets updated without having to
              refresh or stuck in an endless email loop.
            </p>

            <div class="margin-top-base">
              <a href="/auth/signup"
                ><b>Try it out</b> <icon name="arrow_right" size="24"
              /></a>
            </div>
          </a-col>
        </a-row>
      </div>
    </section>

    <section
      class="xcyan-gradient cyan-bg section-with-overflow-screenshot section-dual"
    >
      <div class="container">
        <a-row type="flex" justify="space-between" :gutter="100" align="middle">
          <a-col :span="12">
            <div style="color: #395756" class="margin-bottom-lg">
              <icon
                name="catalog_management"
                size="24"
                class="margin-right-xs"
              />
              <b>Catalog management</b>
            </div>

            <h2>Option management made simple.</h2>

            <p>
              Glasshouse allows your team to manage small, medium and large
              option catalogs with ease. Descriptions, costs, markups and images
              are all accessable to quickly edit when product information
              changes.
            </p>

            <p>
              User permissions allow your management team to decide what a user
              can and cannot edit, improving accuracy and compliance.
            </p>

            <div class="margin-top-base">
              <a href="/auth/signup"
                ><b>Try it out</b> <icon name="arrow_right" size="24"
              /></a>
            </div>
          </a-col>

          <a-col :span="12">
            <img
              src="../../../images/screenshot_catalog.png"
              width="568"
              srcset="
                ../../../images/screenshot_catalog.png    1x,
                ../../../images/screenshot_catalog@2x.png 2x
              "
              class="screenshot"
            />
          </a-col>
        </a-row>
      </div>
    </section>

    <section class="section-features">
      <div class="container">
        <a-row type="flex" justify="space-between" :gutter="100">
          <a-col :span="8">
            <h3>Every feature included in the box.</h3>

            <img
              src="../../../images/package.svg"
              width="260"
              class="margin-top-xl"
            />
          </a-col>

          <a-col :span="24 - 8">
            <a-row type="flex" :gutter="60" class="margin-bottom-lg">
              <a-col :span="12">
                <h6><icon name="doodle_check" size="20" raw /> PDF Export</h6>

                <p>
                  Export PDF documents that provide just the right amount of
                  information. Choose whether to show only what you want to show
                  your office staff, field employees, subcontractors and
                  customers.
                </p>
              </a-col>

              <a-col :span="12">
                <h6>
                  <icon name="doodle_check" size="20" raw /> Product images
                </h6>

                <p>
                  Improve accuracy in the field by providing a resource to view
                  product images and verify that the right products make it to
                  the jobsite.
                </p>
              </a-col>
            </a-row>

            <a-row type="flex" :gutter="60" class="margin-bottom-lg">
              <a-col :span="12">
                <h6>
                  <icon name="doodle_check" size="20" raw /> Bulk import /
                  export
                </h6>

                <p>
                  Glasshouse supports .csv import and export of product data to
                  easily edit, upload or delete product information with ease.
                </p>
              </a-col>

              <a-col :span="12">
                <h6>
                  <icon name="doodle_check" size="20" raw /> Granular
                  permissions
                </h6>

                <p>
                  Permissons can be defined within Glasshouse to improve
                  compliance and customize user experience depending on their
                  role within your organization.
                </p>
              </a-col>
            </a-row>

            <a-row type="flex" :gutter="60">
              <a-col :span="12">
                <h6>
                  <icon name="doodle_check" size="20" raw /> Fully on the cloud
                </h6>

                <p>
                  Glasshouse is a central location for meeting records, product
                  information, floorplans and documents. One source of truth for
                  a team to work from without redundancy.
                </p>
              </a-col>

              <a-col :span="12">
                <h6>
                  <icon name="doodle_check" size="20" raw /> 1 on 1 onboarding
                </h6>

                <p>
                  Glasshouse offers 1 on 1 onboarding to make your transition
                  from paper to Glasshouse successful and swift. Our team is
                  made up of industry veterans who understand design,
                  construction and value customer experience.
                </p>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </section>

    <section class="section cyan-bg prefooter">
      <div class="container">
        <a-row type="flex" justify="space-between" :gutter="50" align="bottom">
          <a-col :span="16">
            <h2>Take your design selection process to the next level.</h2>

            <p>Give it a try for free. No credit card required on signup.</p>

            <a-button
              size="large"
              @click="signup"
              type="primary"
              class="ant-btn-lg margin-top-lg"
              ><b>Get started today </b> <icon name="arrow_right" size="20"
            /></a-button>

            <a-button
              size="large"
              class="ant-btn-lg margin-top-lg margin-left-sm"
              @click.prevent="$bus.$emit('schedule-demo')"
              >Schedule a demo</a-button
            >
          </a-col>

          <a-col>
            <img src="../../../images/doodle_designer.svg" width="340" />
          </a-col>
        </a-row>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },

  created() {},

  methods: {
    signup() {
      window.location = "/auth/signup";
    },
  },

  mounted() {},

  computed: {},

  watch: {},
};
</script>
