<template>
  <a-config-provider>
    <a-select v-on="$listeners" v-bind="$attrs" v-model="selectVal">
      <a-select-option value="" key="placeholder" v-if="placeholder">
        <span class="color-gray" style="font-weight: normal"
          ><icon name="enter_right" size="16" class="fade-40 margin-right-xs" />
          {{ placeholder || "Any" }}</span
        >
      </a-select-option>
      <slot></slot>
    </a-select>

    <template #renderEmpty>
      <div class="empty-state empty-state-compact padding-base">
        <div class="empty-state-content">
          <img src="../../../images/paperplane.svg" />

          <div class="empty-state-title font-medium">
            <b>Nothing found..</b>
          </div>
        </div>
      </div>
    </template>
  </a-config-provider>
</template>

<script>
export default {
  props: ["value", "placeholder", "child"],

  computed: {
    selectVal: {
      get() {
        return this.value === null ? "" : this.value;
      },
      set(val) {
        this.$emit("input", val === "" ? null : val);
      },
    },
  },
};
</script>
