import { Vue } from "../../common/vue";

Vue.mixin({
  methods: {
    render_success(msg) {
      this.$message.success(msg);
    },

    render_error(err) {
      this.$message.error(err);
    },

    articulate_response(rsp) {
      if (rsp.status.code == 200) {
        this.render_success(rsp.status.message);
      } else {
        this.render_error(
          rsp.status.message || "Something went wrong (No error message)",
        );
      }
    },

    articulate_error(rsp) {
      if (rsp.status.code != 200) {
        this.render_error(
          rsp.status.message || "Something went wrong (No error message)",
        );
      }
    },

    logout() {
      this.$api.post("/auth/logout").then((rsp) => {
        window.location = "/auth/login";
      });
    },
  },

  computed: {},
});
