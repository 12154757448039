import Vue from "vue";

const _ = require("./lodash").default;
import { Dragster } from "@matrx/dragster";

import helpers from "./helpers";

Vue.mixin({
  methods: {
    ...helpers,

    // Helper to hook up to bus events without leaking memory
    $hook_bus(vm, ev, func) {
      vm.$bus.$on(ev, func);

      vm.$once("hook:beforeDestroy", () => {
        vm.$bus.$off(ev, func);
      });
    },

    // FIXME: Remove this as soon as possible!
    // Helper to update objects within arrays or destroy them
    // based on payloads received from pusher
    $apply_payload(
      target,
      payload,
      push_func = "unshift",
      inclusion_control = null,
      id_attr = "id",
    ) {
      if (Array.isArray(target)) {
        if (payload._destroyed) {
          let ix = target.findIndex((o) => o[id_attr] === payload[id_attr]);

          if (ix > -1) {
            target[ix]._destroyed = true;
            target.splice(ix, 1);
          }
        } else {
          // remove from target array if no longer meets current filter controls
          let to_dispose =
            inclusion_control && !inclusion_control(payload, target);

          if (to_dispose) {
            let i = _.findIndex(target, { [id_attr]: payload[id_attr] });
            if (i > -1) {
              target.splice(i, 1);
            }
            return;
          }

          // Otherwise mutate target, or push if new record
          let ex = _.find(target, { [id_attr]: payload[id_attr] });

          if (ex) {
            _.extend(ex, payload);
          } else {
            if (push_func !== false) {
              if (typeof push_func === "function") {
                push_func(payload, target);
              } else {
                target[push_func](payload);
              }
            }
          }
        }
      } else if (target && target[id_attr] === payload[id_attr]) {
        if (payload._destroyed) {
          target._destroyed = true;
        } else {
          _.extend(target, payload);
        }
      }
    },
  },

  computed: {
    // FIXME: Why???
    _() {
      return _;
    },

    $bus() {
      return require("./bus").bus;
    },

    $axios() {
      return require("./axios").axios;
    },

    is_production() {
      return process.env.NODE_ENV === "production";
    },

    current_route_name() {
      return this.$route.name;
    },

    is_inspect_mode() {
      return !!document.querySelector("meta[name=inspect-mode]");
    },
  },

  directives: {
    "dnd-events": {
      bind(el) {
        if (!el.id) {
          el.setAttribute("id", `dragster-${_.random(1e20)}`);
        }

        el.__dragster__ = el.__dragster__ || new Dragster(el);
      },

      unbind(el) {
        el.__dragster__.removeListeners();
      },
    },

    "dnd-classes": {
      bind(el) {
        if (!el.id) {
          el.setAttribute("id", `dragster-${_.random(1e20)}`);
        }

        el.__dragster__ = el.__dragster__ || new Dragster(el);

        el.addEventListener(
          "dragster-enter",
          (e) => {
            el.classList.add("dnd-dragging");
          },
          false,
        );

        el.addEventListener(
          "dragster-leave",
          (e) => {
            el.classList.remove("dnd-dragging");
          },
          false,
        );

        el.addEventListener(
          "drop",
          (e) => {
            el.classList.remove("dnd-dragging");
          },
          false,
        );
      },

      unbind(el) {
        if (el.__dragster__) {
          el.__dragster__.removeListeners();
        }
      },
    },

    autofocus: {
      inserted(el, binding) {
        // If directive has bound value
        if (binding.value !== undefined && !binding.value) {
          return;
        }
        // Focus the element
        if (el.tagName.match(/input|textarea|div|span/i)) {
          // el.focus()
          helpers.focus_at_end(el);
        } else {
          let input = el.querySelector('input:not([type="hidden"]), textarea');

          if (input) {
            // input.focus()
            helpers.focus_at_end(input);
          }
        }
      },
    },

    "delayed-autofocus": {
      inserted(el, binding) {
        // If directive has bound value
        if (binding.value !== undefined && !binding.value) {
          return;
        }
        // Focus the element
        if (el.tagName.match(/input|textarea|div|span/i)) {
          // el.focus()
          setTimeout(() => {
            // helpers.focus_at_end(el)
            el.focus();
          }, 200);
        } else {
          let input = el.querySelector('input:not([type="hidden"]), textarea');

          if (input) {
            // input.focus()
            setTimeout(() => {
              // helpers.focus_at_end(input)
              input.focus();
            }, 200);
          }
        }
      },
    },

    "near-end": {
      bind: function (el, binding) {
        let handler = _.throttle(
          (e) => {
            console.log(el.scrollHeight - (el.scrollTop + el.offsetHeight));

            if (el.scrollHeight - (el.scrollTop + el.offsetHeight) < 200) {
              binding.value(e);
            }
          },
          200,
          { trailing: true, leading: true },
        );

        el.__vueNearEnd__ = handler;

        // add Event Listeners
        el.addEventListener("scroll", handler);
      },

      unbind: function (el) {
        // Remove Event Listeners
        el.removeEventListener("scroll", el.__vueNearEnd__);
        el.__vueNearEnd__ = null;
      },
    },

    "isolated-scroll": {
      bind: function (el, binding) {
        var defaultOptions = {
          whenOnlyHasScrollbar: false,
          isolateX: true,
          isolateY: true,
        };

        var options = binding.value || defaultOptions;
        Object.keys(defaultOptions).forEach(function (option) {
          if (!options.hasOwnProperty(option)) {
            options[option] = defaultOptions[option];
          }
        });

        el.addEventListener("wheel", function (event) {
          var scrollWidth = el.scrollWidth;
          var scrollHeight = el.scrollHeight;
          var clientWidth = el.clientWidth;
          var clientHeight = el.clientHeight;
          var hasScrollX = scrollWidth !== clientWidth;
          var hasScrollY = scrollHeight !== clientHeight;

          if (options.whenOnlyHasScrollbar && (!hasScrollX || !hasScrollY)) {
            return;
          }

          var isHorizontalScroll = event.deltaX !== 0;
          var isVerticalScroll = event.deltaY !== 0;
          var isIsolatedScroll = false;

          if (isHorizontalScroll && options.isolateX) {
            var scrollLeft = el.scrollLeft;
            var meetLeft = scrollLeft === 0;
            var meetRight = scrollWidth - clientWidth === scrollLeft;
            var isLeft = event.deltaX < 0;
            isIsolatedScroll = (meetLeft && isLeft) || (meetRight && !isLeft);
            console.log(isIsolatedScroll);
          }

          if (isVerticalScroll && options.isolateY) {
            var scrollTop = el.scrollTop;
            var meetTop = scrollTop === 0;
            var meetBottom = scrollHeight - clientHeight === scrollTop;
            var isUpward = event.deltaY < 0;
            isIsolatedScroll =
              (meetBottom && !isUpward) || (meetTop && isUpward);
          }

          if (isIsolatedScroll) {
            event.preventDefault();
            event.stopPropagation();
          }
        });
      },

      unbind: function (el) {
        el.removeEventListener("wheel", function () {
          console.log("removed");
        });
      },
    },

    "click-outside": {
      bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== "function") {
          const compName = vNode.context.name;
          let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
          if (compName) {
            warn += `Found in component '${compName}'`;
          }

          console.warn(warn);
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueClickOutside__ = handler;

        // add Event Listeners
        document.addEventListener("click", handler);
      },

      unbind: function (el) {
        // Remove Event Listeners
        document.removeEventListener("click", el.__vueClickOutside__);
        el.__vueClickOutside__ = null;
      },
    },

    "mousedown-outside": {
      bind: function (el, binding, vNode) {
        // Provided expression must evaluate to a function.
        if (typeof binding.value !== "function") {
          const compName = vNode.context.name;
          let warn = `[Vue-mousedown-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
          if (compName) {
            warn += `Found in component '${compName}'`;
          }

          console.warn(warn);
        }
        // Define Handler and cache it on the element
        const bubble = binding.modifiers.bubble;
        const handler = (e) => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e);
          }
        };
        el.__vueMouseDownOutside__ = handler;

        // add Event Listeners
        document.addEventListener("mousedown", handler);
      },

      unbind: function (el) {
        // Remove Event Listeners
        document.removeEventListener("mousedown", el.__vueMouseDownOutside__);
        el.__vueMouseDownOutside__ = null;
      },
    },
  },
});

export { Vue };
