<template>
  <div id="marketing">
    <header class="header">
      <a-row type="flex" align="middle" justify="space-between">
        <a-col>
          <nav>
            <a href="/">
              <img src="../../images/logo.svg" class="header-logo" />
            </a>

            <a href="#" @click.prevent="$bus.$emit('schedule-demo')">
              Request a demo
            </a>
            <a href="/pricing">Pricing</a>
            <a href="/auth/signup">Signup</a>
          </nav>
        </a-col>

        <a-col>
          <nav>
            <a href="/auth/login">Sign in</a>
            <a-button @click="signup" type="primary">
              <b>Join Glasshouse </b>
              <icon name="arrow_right" size="20" />
            </a-button>
          </nav>
        </a-col>
      </a-row>
    </header>

    <router-view />

    <footer class="footer">
      <div class="container">
        <h4>Made by people in the industry.</h4>

        <a-row type="flex" class="margin-top-lg">
          <a-col :span="2">
            <img src="../../images/black_logo_symbol.svg" width="34" />
          </a-col>

          <a-col :span="4">
            <h6>Resources</h6>

            <a href="https://help.glasshouse.build">Help center</a><br />
            <a href="#">Service status</a>
          </a-col>

          <a-col :span="4">
            <h6>Follow us</h6>

            <a href="#">Twitter</a><br />
            <a href="https://instagram.com/glasshouse.build" target="_blank">
              Instagram
            </a>
            <br />
            <a
              href="https://www.linkedin.com/company/glasshouseoptions"
              target="_blank"
            >
              Linkedin
            </a>
          </a-col>

          <a-col :span="4">
            <h6>Legal</h6>

            <a href="/terms">Terms of service</a><br />
            <a href="/privacy">Privacy policy</a><br />
          </a-col>

          <a-col :span="6">
            <h6>Reach out</h6>

            <a href="mailto:hello@glasshouse.build">hello@glasshouse.build</a>
          </a-col>

          <a-col :span="4">
            <h6>© All rights reserved</h6>

            <p>Glasshouse Technologies, Inc</p>
          </a-col>
        </a-row>
      </div>
    </footer>
  </div>
</template>

<script>
import "../../css/4-vendors/antd_marketing.less";
import "../../css/marketing.scss";

export default {
  name: "GlMarketing",
  created() {
    this.$axios.defaults.headers.common["X-CSRF-Token"] = (
      document.querySelector("meta[name=csrf-token]") || {}
    ).content;

    this.$bus.$on("schedule-demo", () => {
      Calendly.initPopupWidget({
        url: "https://calendly.com/glasshouse_build",
      });
    });
  },

  methods: {
    signup() {
      window.location = "/auth/signup";
    },
  },
};
</script>
