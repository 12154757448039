const _ = {
  last: require("lodash/last"),
  each: require("lodash/each"),
  find: require("lodash/find"),
  findIndex: require("lodash/findIndex"),
  map: require("lodash/map"),
  groupBy: require("lodash/groupBy"),
  filter: require("lodash/filter"),
  identity: require("lodash/identity"),
  throttle: require("lodash/throttle"),
  debounce: require("lodash/debounce"),
  extend: require("lodash/extend"),
  startCase: require("lodash/startCase"),
  random: require("lodash/random"),
  sum: require("lodash/sum"),
  round: require("lodash/round"),
  words: require("lodash/words"),
  toLower: require("lodash/toLower"),
  kebabCase: require("lodash/kebabCase"),
  take: require("lodash/take"),
  capitalize: require("lodash/capitalize"),
  merge: require("lodash/merge"),
  values: require("lodash/values"),
  keys: require("lodash/keys"),
  pick: require("lodash/pick"),
  pickBy: require("lodash/pickBy"),
  sortBy: require("lodash/sortBy"),
  range: require("lodash/range"),
  cloneDeep: require("lodash/cloneDeep"),
  sample: require("lodash/sample"),
  uniq: require("lodash/uniq"),
  flatten: require("lodash/flatten"),
  fromPairs: require("lodash/fromPairs"),
  toPairs: require("lodash/toPairs"),
  without: require("lodash/without"),
  truncate: require("lodash/truncate"),
  remove: require("lodash/remove"),
  compact: require("lodash/compact"),
  isEqual: require("lodash/isEqual"),
};

export default _;
