<template>
  <i
    v-on="$listeners"
    v-bind="$attrs"
    :class="`anticon custom anticon-${name} ${
      block !== undefined ? 'block' : ''
    }`"
    v-show="ready"
  ></i>
</template>

<script>
const Color = require("color");

export default {
  props: [
    "name",
    "size",
    "color",
    "twoTone",
    "raw",
    "wrap",
    "block",
    "scale",
    "altcolor",
  ],

  mounted() {
    this.render_icon();
  },

  data() {
    return {
      ready: false,
    };
  },

  methods: {
    render_icon() {
      let xml = require(`!html-loader!../../../images/icons/${this.name}.svg`);

      if (this.raw === undefined || this.raw === false) {
        if (this.twoTone !== undefined || this.twoTone === true) {
          xml = xml.replace(/#1D1D1D/i, this.resolved_color);
          xml = xml.replace(/#D0D0D0/i, this.secondary_color);
        } else {
          xml = xml.replace(/#([a-f0-9]{3}){1,2}\b/i, this.resolved_color);
        }
      }

      this.ready = false;

      if (this.wrap !== undefined) {
        xml = `<svg xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink" width="${this.width}" height="${this.height}">
        ${xml}
        </svg>
        `;
      }

      this.$el.innerHTML = xml;

      let svg = this.$el.querySelector("svg");

      if (this.width) {
        svg.setAttribute("width", this.width);
      } else {
        svg.removeAttribute("width");
      }

      if (this.height) {
        svg.setAttribute("height", this.height);
      } else {
        svg.removeAttribute("height");
      }

      if (this.wrap !== undefined) {
        let inner_svg = svg.querySelector("svg");

        if (this.scale) {
          inner_svg.setAttribute(
            "width",
            inner_svg.width.baseVal.value * +this.scale,
          );
          inner_svg.setAttribute(
            "height",
            inner_svg.height.baseVal.value * +this.scale,
          );
        }

        inner_svg.setAttribute(
          "x",
          Math.round(
            svg.width.baseVal.value / 2 -
              (inner_svg.width.baseVal.value / 2).toString(),
          ),
        );
        inner_svg.setAttribute(
          "y",
          Math.round(
            svg.height.baseVal.value / 2 -
              (inner_svg.height.baseVal.value / 2).toString(),
          ),
        );
      }

      svg.setAttribute("fill", this.resolved_color);

      this.ready = true;
    },
  },

  computed: {
    width() {
      return this.size_parts && this.size_parts[0] ? this.size_parts[0] : null;
    },

    height() {
      return this.size_parts && this.size_parts[1] ? this.size_parts[1] : "1em";
    },

    size_parts() {
      if (this.size) {
        return this.size.match(/x/i)
          ? this.size.split(/x/i)
          : [this.size, this.size];
      }
    },

    resolved_color() {
      if (this.color) {
        return (
          {
            $black: "black",
            $darkgray: "#5b5b5b",
            $blue: "#2596FF",
            $orange: "#FF5722",
            $red: "#FF2537",
          }[this.color] || this.color
        );
      } else {
        if (this.twoTone !== undefined || this.twoTone === true) {
          return getComputedStyle(this.$el)["color"]
            ? Color(getComputedStyle(this.$el)["color"]).rgb().hex()
            : "currentColor";
        } else {
          return "currentColor";
        }
      }
    },

    secondary_color() {
      if (this.altcolor) {
        return this.altcolor;
      } else if (this.resolved_color && this.resolved_color != "currentColor") {
        return Color(this.resolved_color).mix(Color("white"), 0.75).rgb().hex();
      }
    },
  },

  watch: {
    name: function () {
      this.render_icon();
    },

    size: function () {
      this.render_icon();
    },

    color: function () {
      this.render_icon();
    },

    altcolor: function () {
      this.render_icon();
    },
  },
};
</script>
