import Index from "../components/marketing/index.vue";
import Privacy from "../components/marketing/privacy.vue";
import Terms from "../components/marketing/terms.vue";
import Pricing from "../components/marketing/pricing.vue";

const routes = [
  { path: "/", component: Index, name: "index" },
  { path: "/privacy", component: Privacy, name: "privacy" },
  { path: "/terms", component: Terms, name: "terms" },
  { path: "/pricing", component: Pricing, name: "pricing" },
];

export { routes };
