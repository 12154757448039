<template>
  <!--              <a class="ant-dropdown-link" @click="e => e.preventDefault()">-->
  <!--                Click me <a-icon type="down" />-->
  <!--              </a>-->
  <div
    :style="{
      width: final_size + 'px',
      height: final_size + 'px',
      fontSize: text_size + 'px',
      backgroundImage: `url(${image_url})`,
    }"
    class="user-avatar"
    :class="{ 'user-avatar-has-image': image_url }"
  >
    <span class="user-avatar-abbv" v-if="!image_url">{{
      is_compact ? abbv.substr(0, 1) : abbv
    }}</span>
  </div>
</template>

<script>
export default {
  props: ["user", "size"],

  methods: {},

  computed: {
    abbv() {
      if (this.user) {
        return (this.user.full_name || this.user.email)
          .split(" ")
          .map((n) => n[0])
          .join("")
          .toUpperCase();
      } else {
        return "?";
      }
    },

    image_url() {
      if (this.user && this.user.avatar) {
        return this.user.avatar.small;
      }
      return "";
    },

    text_size() {
      return (
        2 * Math.round((this.final_size * (this.is_compact ? 0.6 : 0.4)) / 2)
      );
    },

    final_size() {
      return this.size || 24;
    },

    is_compact() {
      return this.final_size <= 20;
    },
  },
};
</script>
