export default {
  prettify_length(n) {
    if (n == 0) {
      return '0"';
    }

    let feet = Math.floor(n / 12);
    let inches = n - feet * 12;

    let s = "";

    if (feet) {
      s += `${feet}'`;
    }

    if (inches) {
      s += `${inches}"`;
    }

    return s;
  },

  money_format(n, p = 2) {
    return this.number_with_commas(n, p);
  },

  number_with_commas(n, precision = 0) {
    return new Intl.NumberFormat("en-US", {
      maximumFractionDigits: precision,
      minimumFractionDigits: precision,
    }).format(n);
    // return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },

  bytes_to_size(bytes, seperator = "") {
    const sizes = ["bytes", "kb", "mb", "gb", "tb"];
    if (bytes == 0) return "empty";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)}${seperator}${sizes[i]}`;
  },

  truncate_middle(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;

    separator = separator || "...";

    var sepLen = separator.length,
      charsToShow = strLen - sepLen,
      frontChars = Math.ceil(charsToShow / 2),
      backChars = Math.floor(charsToShow / 2);

    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  },

  pluralize(n, s) {
    return `${n} ${s}${n == 1 ? "" : "s"}`;
  },

  to_percent(a, b) {
    return (Math.round((b / a) * 100) || 0) + "%";
  },

  uuid() {
    var uuid = "",
      i,
      random;
    for (i = 0; i < 32; i++) {
      random = (Math.random() * 16) | 0;

      if (i == 8 || i == 12 || i == 16 || i == 20) {
        uuid += "-";
      }
      uuid += (i == 12 ? 4 : i == 16 ? (random & 3) | 8 : random).toString(16);
    }
    return uuid;
  },

  scroll_to_top() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },

  _copy(text) {
    let dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  },

  focus_at_end(el) {
    el.focus();

    if (
      typeof window.getSelection != "undefined" &&
      typeof document.createRange != "undefined"
    ) {
      var range = document.createRange();
      range.selectNodeContents(el);
      range.collapse(false);
      var sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (typeof document.body.createTextRange != "undefined") {
      var textRange = document.body.createTextRange();
      textRange.moveToElementText(el);
      textRange.collapse(false);
      textRange.select();
    }
  },
};
